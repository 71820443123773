import { types } from "@probable-futures/lib";

export const datasets: types.Map[] = [
  {
    mapStyleId: "clhyr76qr028501pr9vjx9a1d",
    name: "Days above 32°C (90°F)",
    stops: [1, 8, 31, 91, 181],
    binHexColors: ["#515866", "#0ed5a3", "#0099e4", "#8be1ff", "#ff45d0", "#d70066"],
    isDiff: false,
    step: 1,
    binLabels: null,
    binningType: "range",
    slug: "days_above_32c",
    mapVersion: 3,
    isLatest: true,
    dataLabels: ["5th percentile", "average", "95th percentile"],
    methodUsedForMid: "mean",
    dataset: {
      id: 40104,
      name: "Number of days maximum temperature above 32°C (90°F)",
      unit: "days",
      minValue: 0,
      maxValue: 365,
      dataVariables: null,
      pfDatasetUnitByUnit: {
        unitLong: "Number of days per year",
      },
      pfDatasetParentCategoryByParentCategory: {
        name: "heat",
        label: "Maps of temperature",
      },
      subCategory: "increasing heat",
    },
  },
  {
    mapStyleId: "cl59m7jcm001414o1hqf8x68l",
    name: "Days above 32°C (90°F)",
    stops: [1, 8, 31, 91, 181],
    binHexColors: ["#515866", "#0ed5a3", "#0099e4", "#8be1ff", "#ff45d0", "#d70066"],
    isDiff: false,
    step: 1,
    binLabels: null,
    binningType: "range",
    slug: "days_above_32c",
    mapVersion: 1,
    isLatest: false,
    dataLabels: ["5th percentile", "average", "95th percentile"],
    methodUsedForMid: "mean",
    dataset: {
      id: 40104,
      name: "Number of days maximum temperature above 32°C (90°F)",
      unit: "days",
      minValue: 0,
      maxValue: 365,
      dataVariables: null,
      pfDatasetUnitByUnit: {
        unitLong: "Number of days per year",
      },
      pfDatasetParentCategoryByParentCategory: {
        name: "heat",
        label: "Maps of temperature",
      },
      subCategory: "increasing heat",
    },
  },
  {
    mapStyleId: "cldu7iyay001901pl0q2v96w9",
    name: "Days above 32°C (90°F)",
    stops: [1, 8, 31, 91, 181],
    binHexColors: ["#515866", "#0ed5a3", "#0099e4", "#8be1ff", "#ff45d0", "#d70066"],
    isDiff: false,
    step: 1,
    binLabels: null,
    binningType: "range",
    slug: "days_above_32c",
    mapVersion: 2,
    isLatest: false,
    dataLabels: ["5th percentile", "average", "95th percentile"],
    methodUsedForMid: "mean",
    dataset: {
      id: 40104,
      name: "Number of days maximum temperature above 32°C (90°F)",
      unit: "days",
      minValue: 0,
      maxValue: 365,
      dataVariables: null,
      pfDatasetUnitByUnit: {
        unitLong: "Number of days per year",
      },
      pfDatasetParentCategoryByParentCategory: {
        name: "heat",
        label: "Maps of temperature",
      },
      subCategory: "increasing heat",
    },
  },
  {
    mapStyleId: "cl59mxfs9001x14lrn1wdpqbk",
    name: "Days above 35°C (95°F)",
    stops: [1, 8, 31, 91, 181],
    binHexColors: ["#515866", "#0ed5a3", "#0099e4", "#8be1ff", "#ff45d0", "#d70066"],
    isDiff: false,
    step: 1,
    binLabels: null,
    binningType: "range",
    slug: "days_above_35c",
    mapVersion: 1,
    isLatest: false,
    dataLabels: ["5th percentile", "average", "95th percentile"],
    methodUsedForMid: "mean",
    dataset: {
      id: 40105,
      name: "Number of days maximum temperature above 35°C (95°F)",
      unit: "days",
      minValue: 0,
      maxValue: 365,
      dataVariables: null,
      pfDatasetUnitByUnit: {
        unitLong: "Number of days per year",
      },
      pfDatasetParentCategoryByParentCategory: {
        name: "heat",
        label: "Maps of temperature",
      },
      subCategory: "increasing heat",
    },
  },
  {
    mapStyleId: "cle5i7fwp003c01qgbsqmg1tj",
    name: "Days above 35°C (95°F)",
    stops: [1, 8, 31, 91, 181],
    binHexColors: ["#515866", "#0ed5a3", "#0099e4", "#8be1ff", "#ff45d0", "#d70066"],
    isDiff: false,
    step: 1,
    binLabels: null,
    binningType: "range",
    slug: "days_above_35c",
    mapVersion: 2,
    isLatest: false,
    dataLabels: ["5th percentile", "average", "95th percentile"],
    methodUsedForMid: "mean",
    dataset: {
      id: 40105,
      name: "Number of days maximum temperature above 35°C (95°F)",
      unit: "days",
      minValue: 0,
      maxValue: 365,
      dataVariables: null,
      pfDatasetUnitByUnit: {
        unitLong: "Number of days per year",
      },
      pfDatasetParentCategoryByParentCategory: {
        name: "heat",
        label: "Maps of temperature",
      },
      subCategory: "increasing heat",
    },
  },
  {
    mapStyleId: "clhyrro7r028j01quaigo1l8g",
    name: "Days above 35°C (95°F)",
    stops: [1, 8, 31, 91, 181],
    binHexColors: ["#515866", "#0ed5a3", "#0099e4", "#8be1ff", "#ff45d0", "#d70066"],
    isDiff: false,
    step: 1,
    binLabels: null,
    binningType: "range",
    slug: "days_above_35c",
    mapVersion: 3,
    isLatest: true,
    dataLabels: ["5th percentile", "average", "95th percentile"],
    methodUsedForMid: "mean",
    dataset: {
      id: 40105,
      name: "Number of days maximum temperature above 35°C (95°F)",
      unit: "days",
      minValue: 0,
      maxValue: 365,
      dataVariables: null,
      pfDatasetUnitByUnit: {
        unitLong: "Number of days per year",
      },
      pfDatasetParentCategoryByParentCategory: {
        name: "heat",
        label: "Maps of temperature",
      },
      subCategory: "increasing heat",
    },
  },
  {
    mapStyleId: "cle5ik4xw001401qtmx3iupap",
    name: "Days above 38°C (100°F)",
    stops: [1, 8, 31, 91, 181],
    binHexColors: ["#515866", "#0ed5a3", "#0099e4", "#8be1ff", "#ff45d0", "#d70066"],
    isDiff: false,
    step: 1,
    binLabels: null,
    binningType: "range",
    slug: "days_above_38c",
    mapVersion: 2,
    isLatest: false,
    dataLabels: ["5th percentile", "average", "95th percentile"],
    methodUsedForMid: "mean",
    dataset: {
      id: 40106,
      name: "Number of days maximum temperature above 38°C (100°F)",
      unit: "days",
      minValue: 0,
      maxValue: 365,
      dataVariables: null,
      pfDatasetUnitByUnit: {
        unitLong: "Number of days per year",
      },
      pfDatasetParentCategoryByParentCategory: {
        name: "heat",
        label: "Maps of temperature",
      },
      subCategory: "increasing heat",
    },
  },
  {
    mapStyleId: "clhyrwhlt02ae01qu6uojdjhx",
    name: "Days above 38°C (100°F)",
    stops: [1, 8, 31, 91, 181],
    binHexColors: ["#515866", "#0ed5a3", "#0099e4", "#8be1ff", "#ff45d0", "#d70066"],
    isDiff: false,
    step: 1,
    binLabels: null,
    binningType: "range",
    slug: "days_above_38c",
    mapVersion: 3,
    isLatest: true,
    dataLabels: ["5th percentile", "average", "95th percentile"],
    methodUsedForMid: "mean",
    dataset: {
      id: 40106,
      name: "Number of days maximum temperature above 38°C (100°F)",
      unit: "days",
      minValue: 0,
      maxValue: 365,
      dataVariables: null,
      pfDatasetUnitByUnit: {
        unitLong: "Number of days per year",
      },
      pfDatasetParentCategoryByParentCategory: {
        name: "heat",
        label: "Maps of temperature",
      },
      subCategory: "increasing heat",
    },
  },
  {
    mapStyleId: "cl59ntt93004o14l9j4j4vrxq",
    name: "Days above 38°C (100°F)",
    stops: [1, 8, 31, 91, 181],
    binHexColors: ["#515866", "#0ed5a3", "#0099e4", "#8be1ff", "#ff45d0", "#d70066"],
    isDiff: false,
    step: 1,
    binLabels: null,
    binningType: "range",
    slug: "days_above_38c",
    mapVersion: 1,
    isLatest: false,
    dataLabels: ["5th percentile", "average", "95th percentile"],
    methodUsedForMid: "mean",
    dataset: {
      id: 40106,
      name: "Number of days maximum temperature above 38°C (100°F)",
      unit: "days",
      minValue: 0,
      maxValue: 365,
      dataVariables: null,
      pfDatasetUnitByUnit: {
        unitLong: "Number of days per year",
      },
      pfDatasetParentCategoryByParentCategory: {
        name: "heat",
        label: "Maps of temperature",
      },
      subCategory: "increasing heat",
    },
  },
  {
    mapStyleId: "cl59gpzny00e814llii4s2wlu",
    name: "Average temperature",
    stops: [1, 8, 15, 26, 32],
    binHexColors: ["#515866", "#0ed5a3", "#0099e4", "#8be1ff", "#ff45d0", "#d70066"],
    isDiff: false,
    step: 1,
    binLabels: null,
    binningType: "range",
    slug: "average_temperature",
    mapVersion: 1,
    isLatest: false,
    dataLabels: ["5th percentile", "average", "95th percentile"],
    methodUsedForMid: "mean",
    dataset: {
      id: 40101,
      name: "Average Temperature",
      unit: "°C",
      minValue: -30,
      maxValue: 60,
      dataVariables: null,
      pfDatasetUnitByUnit: {
        unitLong: "Temperature (°C)",
      },
      pfDatasetParentCategoryByParentCategory: {
        name: "heat",
        label: "Maps of temperature",
      },
      subCategory: "increasing heat",
    },
  },
  {
    mapStyleId: "cle5ethd3000x01qtrgko6fmv",
    name: "Average temperature",
    stops: [1, 8, 15, 26, 32],
    binHexColors: ["#515866", "#0ed5a3", "#0099e4", "#8be1ff", "#ff45d0", "#d70066"],
    isDiff: false,
    step: 1,
    binLabels: null,
    binningType: "range",
    slug: "average_temperature",
    mapVersion: 2,
    isLatest: false,
    dataLabels: ["5th percentile", "average", "95th percentile"],
    methodUsedForMid: "mean",
    dataset: {
      id: 40101,
      name: "Average Temperature",
      unit: "°C",
      minValue: -30,
      maxValue: 60,
      dataVariables: null,
      pfDatasetUnitByUnit: {
        unitLong: "Temperature (°C)",
      },
      pfDatasetParentCategoryByParentCategory: {
        name: "heat",
        label: "Maps of temperature",
      },
      subCategory: "increasing heat",
    },
  },
  {
    mapStyleId: "clhyqgvkg004i01obb8pm9zto",
    name: "Average temperature",
    stops: [1, 8, 15, 26, 32],
    binHexColors: ["#515866", "#0ed5a3", "#0099e4", "#8be1ff", "#ff45d0", "#d70066"],
    isDiff: false,
    step: 1,
    binLabels: null,
    binningType: "range",
    slug: "average_temperature",
    mapVersion: 3,
    isLatest: true,
    dataLabels: ["5th percentile", "average", "95th percentile"],
    methodUsedForMid: "mean",
    dataset: {
      id: 40101,
      name: "Average Temperature",
      unit: "°C",
      minValue: -30,
      maxValue: 60,
      dataVariables: null,
      pfDatasetUnitByUnit: {
        unitLong: "Temperature (°C)",
      },
      pfDatasetParentCategoryByParentCategory: {
        name: "heat",
        label: "Maps of temperature",
      },
      subCategory: "increasing heat",
    },
  },
  {
    mapStyleId: "cle5fqrhd002q01qmcd7rk940",
    name: "Average daytime temperature",
    stops: [1, 8, 15, 26, 32],
    binHexColors: ["#515866", "#0ed5a3", "#0099e4", "#8be1ff", "#ff45d0", "#d70066"],
    isDiff: false,
    step: 1,
    binLabels: null,
    binningType: "range",
    slug: "average_daytime_temperature",
    mapVersion: 2,
    isLatest: false,
    dataLabels: ["5th percentile", "average", "95th percentile"],
    methodUsedForMid: "mean",
    dataset: {
      id: 40102,
      name: "Maximum temperature",
      unit: "°C",
      minValue: -30,
      maxValue: 60,
      dataVariables: null,
      pfDatasetUnitByUnit: {
        unitLong: "Temperature (°C)",
      },
      pfDatasetParentCategoryByParentCategory: {
        name: "heat",
        label: "Maps of temperature",
      },
      subCategory: "increasing heat",
    },
  },
  {
    mapStyleId: "clhyqxusn00aq01qseqtc3om9",
    name: "Average daytime temperature",
    stops: [1, 8, 15, 26, 32],
    binHexColors: ["#515866", "#0ed5a3", "#0099e4", "#8be1ff", "#ff45d0", "#d70066"],
    isDiff: false,
    step: 1,
    binLabels: null,
    binningType: "range",
    slug: "average_daytime_temperature",
    mapVersion: 3,
    isLatest: true,
    dataLabels: ["5th percentile", "average", "95th percentile"],
    methodUsedForMid: "mean",
    dataset: {
      id: 40102,
      name: "Maximum temperature",
      unit: "°C",
      minValue: -30,
      maxValue: 60,
      dataVariables: null,
      pfDatasetUnitByUnit: {
        unitLong: "Temperature (°C)",
      },
      pfDatasetParentCategoryByParentCategory: {
        name: "heat",
        label: "Maps of temperature",
      },
      subCategory: "increasing heat",
    },
  },
  {
    mapStyleId: "cl59hjp67000u14o5o2wokukw",
    name: "Average daytime temperature",
    stops: [1, 8, 15, 26, 32],
    binHexColors: ["#515866", "#0ed5a3", "#0099e4", "#8be1ff", "#ff45d0", "#d70066"],
    isDiff: false,
    step: 1,
    binLabels: null,
    binningType: "range",
    slug: "average_daytime_temperature",
    mapVersion: 1,
    isLatest: false,
    dataLabels: ["5th percentile", "average", "95th percentile"],
    methodUsedForMid: "mean",
    dataset: {
      id: 40102,
      name: "Maximum temperature",
      unit: "°C",
      minValue: -30,
      maxValue: 60,
      dataVariables: null,
      pfDatasetUnitByUnit: {
        unitLong: "Temperature (°C)",
      },
      pfDatasetParentCategoryByParentCategory: {
        name: "heat",
        label: "Maps of temperature",
      },
      subCategory: "increasing heat",
    },
  },
  {
    mapStyleId: "cl59lfhsw004n14n7uivj4q6k",
    name: "10 hottest days",
    stops: [1, 8, 15, 26, 32],
    binHexColors: ["#515866", "#0ed5a3", "#0099e4", "#8be1ff", "#ff45d0", "#d70066"],
    isDiff: false,
    step: 1,
    binLabels: null,
    binningType: "range",
    slug: "10_hottest_days",
    mapVersion: 1,
    isLatest: false,
    dataLabels: ["5th percentile", "average", "95th percentile"],
    methodUsedForMid: "mean",
    dataset: {
      id: 40103,
      name: "Temperature of the hottest 10 days in any given year",
      unit: "°C",
      minValue: -30,
      maxValue: 60,
      dataVariables: null,
      pfDatasetUnitByUnit: {
        unitLong: "Temperature (°C)",
      },
      pfDatasetParentCategoryByParentCategory: {
        name: "heat",
        label: "Maps of temperature",
      },
      subCategory: "increasing heat",
    },
  },
  {
    mapStyleId: "clhyr2h5b025l01r0f0xa9kzb",
    name: "10 hottest days",
    stops: [1, 8, 15, 26, 32],
    binHexColors: ["#515866", "#0ed5a3", "#0099e4", "#8be1ff", "#ff45d0", "#d70066"],
    isDiff: false,
    step: 1,
    binLabels: null,
    binningType: "range",
    slug: "10_hottest_days",
    mapVersion: 3,
    isLatest: true,
    dataLabels: ["5th percentile", "average", "95th percentile"],
    methodUsedForMid: "mean",
    dataset: {
      id: 40103,
      name: "Temperature of the hottest 10 days in any given year",
      unit: "°C",
      minValue: -30,
      maxValue: 60,
      dataVariables: null,
      pfDatasetUnitByUnit: {
        unitLong: "Temperature (°C)",
      },
      pfDatasetParentCategoryByParentCategory: {
        name: "heat",
        label: "Maps of temperature",
      },
      subCategory: "increasing heat",
    },
  },
  {
    mapStyleId: "cle5gr70l000m01o399u73k8y",
    name: "10 hottest days",
    stops: [1, 8, 15, 26, 32],
    binHexColors: ["#515866", "#0ed5a3", "#0099e4", "#8be1ff", "#ff45d0", "#d70066"],
    isDiff: false,
    step: 1,
    binLabels: null,
    binningType: "range",
    slug: "10_hottest_days",
    mapVersion: 2,
    isLatest: false,
    dataLabels: ["5th percentile", "average", "95th percentile"],
    methodUsedForMid: "mean",
    dataset: {
      id: 40103,
      name: "Temperature of the hottest 10 days in any given year",
      unit: "°C",
      minValue: -30,
      maxValue: 60,
      dataVariables: null,
      pfDatasetUnitByUnit: {
        unitLong: "Temperature (°C)",
      },
      pfDatasetParentCategoryByParentCategory: {
        name: "heat",
        label: "Maps of temperature",
      },
      subCategory: "increasing heat",
    },
  },
  {
    mapStyleId: "cl59ykyfg000c14k1iyl3n2em",
    name: "Freezing days",
    stops: [1, 8, 31, 91, 181],
    binHexColors: ["#515866", "#0ed5a3", "#0099e4", "#8be1ff", "#ff45d0", "#d70066"],
    isDiff: false,
    step: 1,
    binLabels: null,
    binningType: "range",
    slug: "freezing_days",
    mapVersion: 1,
    isLatest: false,
    dataLabels: ["5th percentile", "average", "95th percentile"],
    methodUsedForMid: "mean",
    dataset: {
      id: 40205,
      name: "Number of days maximum temperature below 0°C (32°F)",
      unit: "days",
      minValue: 0,
      maxValue: 365,
      dataVariables: null,
      pfDatasetUnitByUnit: {
        unitLong: "Number of days per year",
      },
      pfDatasetParentCategoryByParentCategory: {
        name: "heat",
        label: "Maps of temperature",
      },
      subCategory: "decreasing cold",
    },
  },
  {
    mapStyleId: "cle5lx0y8003401noyf3pnggm",
    name: "Freezing days",
    stops: [1, 8, 31, 91, 181],
    binHexColors: ["#515866", "#0ed5a3", "#0099e4", "#8be1ff", "#ff45d0", "#d70066"],
    isDiff: false,
    step: 1,
    binLabels: null,
    binningType: "range",
    slug: "freezing_days",
    mapVersion: 2,
    isLatest: false,
    dataLabels: ["5th percentile", "average", "95th percentile"],
    methodUsedForMid: "mean",
    dataset: {
      id: 40205,
      name: "Number of days maximum temperature below 0°C (32°F)",
      unit: "days",
      minValue: 0,
      maxValue: 365,
      dataVariables: null,
      pfDatasetUnitByUnit: {
        unitLong: "Number of days per year",
      },
      pfDatasetParentCategoryByParentCategory: {
        name: "heat",
        label: "Maps of temperature",
      },
      subCategory: "decreasing cold",
    },
  },
  {
    mapStyleId: "clhzz2hvl02as01quh2kqdao4",
    name: "Freezing days",
    stops: [1, 8, 31, 91, 181],
    binHexColors: ["#515866", "#0ed5a3", "#0099e4", "#8be1ff", "#ff45d0", "#d70066"],
    isDiff: false,
    step: 1,
    binLabels: null,
    binningType: "range",
    slug: "freezing_days",
    mapVersion: 3,
    isLatest: true,
    dataLabels: ["5th percentile", "average", "95th percentile"],
    methodUsedForMid: "mean",
    dataset: {
      id: 40205,
      name: "Number of days maximum temperature below 0°C (32°F)",
      unit: "days",
      minValue: 0,
      maxValue: 365,
      dataVariables: null,
      pfDatasetUnitByUnit: {
        unitLong: "Number of days per year",
      },
      pfDatasetParentCategoryByParentCategory: {
        name: "heat",
        label: "Maps of temperature",
      },
      subCategory: "decreasing cold",
    },
  },
  {
    mapStyleId: "clhzykaon02ar01qu0iah4r6c",
    name: "Frost nights",
    stops: [1, 8, 31, 91, 181],
    binHexColors: ["#515866", "#0ed5a3", "#0099e4", "#8be1ff", "#ff45d0", "#d70066"],
    isDiff: false,
    step: 1,
    binLabels: null,
    binningType: "range",
    slug: "frost_nights",
    mapVersion: 3,
    isLatest: true,
    dataLabels: ["5th percentile", "average", "95th percentile"],
    methodUsedForMid: "mean",
    dataset: {
      id: 40202,
      name: "Number of days minimum temperature below 0°C (32°F)",
      unit: "nights",
      minValue: 0,
      maxValue: 365,
      dataVariables: null,
      pfDatasetUnitByUnit: {
        unitLong: "Number of nights per year",
      },
      pfDatasetParentCategoryByParentCategory: {
        name: "heat",
        label: "Maps of temperature",
      },
      subCategory: "decreasing cold",
    },
  },
  {
    mapStyleId: "cl59y3a8v000714lgpllqj741",
    name: "Frost nights",
    stops: [1, 8, 31, 91, 181],
    binHexColors: ["#515866", "#0ed5a3", "#0099e4", "#8be1ff", "#ff45d0", "#d70066"],
    isDiff: false,
    step: 1,
    binLabels: null,
    binningType: "range",
    slug: "frost_nights",
    mapVersion: 1,
    isLatest: false,
    dataLabels: ["5th percentile", "average", "95th percentile"],
    methodUsedForMid: "mean",
    dataset: {
      id: 40202,
      name: "Number of days minimum temperature below 0°C (32°F)",
      unit: "nights",
      minValue: 0,
      maxValue: 365,
      dataVariables: null,
      pfDatasetUnitByUnit: {
        unitLong: "Number of nights per year",
      },
      pfDatasetParentCategoryByParentCategory: {
        name: "heat",
        label: "Maps of temperature",
      },
      subCategory: "decreasing cold",
    },
  },
  {
    mapStyleId: "cle5kmbpx001301ldiwi6k739",
    name: "Frost nights",
    stops: [1, 8, 31, 91, 181],
    binHexColors: ["#515866", "#0ed5a3", "#0099e4", "#8be1ff", "#ff45d0", "#d70066"],
    isDiff: false,
    step: 1,
    binLabels: null,
    binningType: "range",
    slug: "frost_nights",
    mapVersion: 2,
    isLatest: false,
    dataLabels: ["5th percentile", "average", "95th percentile"],
    methodUsedForMid: "mean",
    dataset: {
      id: 40202,
      name: "Number of days minimum temperature below 0°C (32°F)",
      unit: "nights",
      minValue: 0,
      maxValue: 365,
      dataVariables: null,
      pfDatasetUnitByUnit: {
        unitLong: "Number of nights per year",
      },
      pfDatasetParentCategoryByParentCategory: {
        name: "heat",
        label: "Maps of temperature",
      },
      subCategory: "decreasing cold",
    },
  },
  {
    mapStyleId: "cl59y9eeo000b14k14hh6ca78",
    name: "Nights above 20°C (68°F)",
    stops: [1, 8, 31, 91, 181],
    binHexColors: ["#515866", "#0ed5a3", "#0099e4", "#8be1ff", "#ff45d0", "#d70066"],
    isDiff: false,
    step: 1,
    binLabels: null,
    binningType: "range",
    slug: "nights_above_20c",
    mapVersion: 1,
    isLatest: false,
    dataLabels: ["5th percentile", "average", "95th percentile"],
    methodUsedForMid: "mean",
    dataset: {
      id: 40203,
      name: "Number of days minimum temperature above 20°C (68°F)",
      unit: "nights",
      minValue: 0,
      maxValue: 365,
      dataVariables: null,
      pfDatasetUnitByUnit: {
        unitLong: "Number of nights per year",
      },
      pfDatasetParentCategoryByParentCategory: {
        name: "heat",
        label: "Maps of temperature",
      },
      subCategory: "decreasing cold",
    },
  },
  {
    mapStyleId: "clhzypp66028901r05kixdepa",
    name: "Nights above 20°C (68°F)",
    stops: [1, 8, 31, 91, 181],
    binHexColors: ["#515866", "#0ed5a3", "#0099e4", "#8be1ff", "#ff45d0", "#d70066"],
    isDiff: false,
    step: 1,
    binLabels: null,
    binningType: "range",
    slug: "nights_above_20c",
    mapVersion: 3,
    isLatest: true,
    dataLabels: ["5th percentile", "average", "95th percentile"],
    methodUsedForMid: "mean",
    dataset: {
      id: 40203,
      name: "Number of days minimum temperature above 20°C (68°F)",
      unit: "nights",
      minValue: 0,
      maxValue: 365,
      dataVariables: null,
      pfDatasetUnitByUnit: {
        unitLong: "Number of nights per year",
      },
      pfDatasetParentCategoryByParentCategory: {
        name: "heat",
        label: "Maps of temperature",
      },
      subCategory: "decreasing cold",
    },
  },
  {
    mapStyleId: "cle5l2sbs007y01tes0li5a2z",
    name: "Nights above 20°C (68°F)",
    stops: [1, 8, 31, 91, 181],
    binHexColors: ["#515866", "#0ed5a3", "#0099e4", "#8be1ff", "#ff45d0", "#d70066"],
    isDiff: false,
    step: 1,
    binLabels: null,
    binningType: "range",
    slug: "nights_above_20c",
    mapVersion: 2,
    isLatest: false,
    dataLabels: ["5th percentile", "average", "95th percentile"],
    methodUsedForMid: "mean",
    dataset: {
      id: 40203,
      name: "Number of days minimum temperature above 20°C (68°F)",
      unit: "nights",
      minValue: 0,
      maxValue: 365,
      dataVariables: null,
      pfDatasetUnitByUnit: {
        unitLong: "Number of nights per year",
      },
      pfDatasetParentCategoryByParentCategory: {
        name: "heat",
        label: "Maps of temperature",
      },
      subCategory: "decreasing cold",
    },
  },
  {
    mapStyleId: "cl59ygl4z000814lgv5kqsdz7",
    name: "Nights above 25°C (77°F)",
    stops: [1, 8, 31, 91, 181],
    binHexColors: ["#515866", "#0ed5a3", "#0099e4", "#8be1ff", "#ff45d0", "#d70066"],
    isDiff: false,
    step: 1,
    binLabels: null,
    binningType: "range",
    slug: "nights_above_25c",
    mapVersion: 1,
    isLatest: false,
    dataLabels: ["5th percentile", "average", "95th percentile"],
    methodUsedForMid: "mean",
    dataset: {
      id: 40204,
      name: "Number of days minimum temperature above 25°C (77°F)",
      unit: "nights",
      minValue: 0,
      maxValue: 365,
      dataVariables: null,
      pfDatasetUnitByUnit: {
        unitLong: "Number of nights per year",
      },
      pfDatasetParentCategoryByParentCategory: {
        name: "heat",
        label: "Maps of temperature",
      },
      subCategory: "decreasing cold",
    },
  },
  {
    mapStyleId: "clhzyvdlw02dm01qydo7s7fmb",
    name: "Nights above 25°C (77°F)",
    stops: [1, 8, 31, 91, 181],
    binHexColors: ["#515866", "#0ed5a3", "#0099e4", "#8be1ff", "#ff45d0", "#d70066"],
    isDiff: false,
    step: 1,
    binLabels: null,
    binningType: "range",
    slug: "nights_above_25c",
    mapVersion: 3,
    isLatest: true,
    dataLabels: ["5th percentile", "average", "95th percentile"],
    methodUsedForMid: "mean",
    dataset: {
      id: 40204,
      name: "Number of days minimum temperature above 25°C (77°F)",
      unit: "nights",
      minValue: 0,
      maxValue: 365,
      dataVariables: null,
      pfDatasetUnitByUnit: {
        unitLong: "Number of nights per year",
      },
      pfDatasetParentCategoryByParentCategory: {
        name: "heat",
        label: "Maps of temperature",
      },
      subCategory: "decreasing cold",
    },
  },
  {
    mapStyleId: "cle5lfg8b006b01mt7dgi3k65",
    name: "Nights above 25°C (77°F)",
    stops: [1, 8, 31, 91, 181],
    binHexColors: ["#515866", "#0ed5a3", "#0099e4", "#8be1ff", "#ff45d0", "#d70066"],
    isDiff: false,
    step: 1,
    binLabels: null,
    binningType: "range",
    slug: "nights_above_25c",
    mapVersion: 2,
    isLatest: false,
    dataLabels: ["5th percentile", "average", "95th percentile"],
    methodUsedForMid: "mean",
    dataset: {
      id: 40204,
      name: "Number of days minimum temperature above 25°C (77°F)",
      unit: "nights",
      minValue: 0,
      maxValue: 365,
      dataVariables: null,
      pfDatasetUnitByUnit: {
        unitLong: "Number of nights per year",
      },
      pfDatasetParentCategoryByParentCategory: {
        name: "heat",
        label: "Maps of temperature",
      },
      subCategory: "decreasing cold",
    },
  },
  {
    mapStyleId: "cle5iyoyr001k01q9lzpcqhoh",
    name: "Average nighttime temperature",
    stops: [1, 8, 15, 26, 32],
    binHexColors: ["#515866", "#0ed5a3", "#0099e4", "#8be1ff", "#ff45d0", "#d70066"],
    isDiff: false,
    step: 1,
    binLabels: null,
    binningType: "range",
    slug: "average_nighttime_temperature",
    mapVersion: 2,
    isLatest: false,
    dataLabels: ["5th percentile", "average", "95th percentile"],
    methodUsedForMid: "mean",
    dataset: {
      id: 40201,
      name: "Average nighttime temperature",
      unit: "°C",
      minValue: -30,
      maxValue: 60,
      dataVariables: null,
      pfDatasetUnitByUnit: {
        unitLong: "Temperature (°C)",
      },
      pfDatasetParentCategoryByParentCategory: {
        name: "heat",
        label: "Maps of temperature",
      },
      subCategory: "decreasing cold",
    },
  },
  {
    mapStyleId: "cl59pelwc000014lggv1m6lg2",
    name: "Average nighttime temperature",
    stops: [1, 8, 15, 26, 32],
    binHexColors: ["#515866", "#0ed5a3", "#0099e4", "#8be1ff", "#ff45d0", "#d70066"],
    isDiff: false,
    step: 1,
    binLabels: null,
    binningType: "range",
    slug: "average_nighttime_temperature",
    mapVersion: 1,
    isLatest: false,
    dataLabels: ["5th percentile", "average", "95th percentile"],
    methodUsedForMid: "mean",
    dataset: {
      id: 40201,
      name: "Average nighttime temperature",
      unit: "°C",
      minValue: -30,
      maxValue: 60,
      dataVariables: null,
      pfDatasetUnitByUnit: {
        unitLong: "Temperature (°C)",
      },
      pfDatasetParentCategoryByParentCategory: {
        name: "heat",
        label: "Maps of temperature",
      },
      subCategory: "decreasing cold",
    },
  },
  {
    mapStyleId: "clhzyf384028601r0br6g94y6",
    name: "Average nighttime temperature",
    stops: [1, 8, 15, 26, 32],
    binHexColors: ["#515866", "#0ed5a3", "#0099e4", "#8be1ff", "#ff45d0", "#d70066"],
    isDiff: false,
    step: 1,
    binLabels: null,
    binningType: "range",
    slug: "average_nighttime_temperature",
    mapVersion: 3,
    isLatest: true,
    dataLabels: ["5th percentile", "average", "95th percentile"],
    methodUsedForMid: "mean",
    dataset: {
      id: 40201,
      name: "Average nighttime temperature",
      unit: "°C",
      minValue: -30,
      maxValue: 60,
      dataVariables: null,
      pfDatasetUnitByUnit: {
        unitLong: "Temperature (°C)",
      },
      pfDatasetParentCategoryByParentCategory: {
        name: "heat",
        label: "Maps of temperature",
      },
      subCategory: "decreasing cold",
    },
  },
  {
    mapStyleId: "clhzzu7r2028d01r01jasa97q",
    name: "Days above 26°C (78°F) wet-bulb",
    stops: [1, 4, 8, 15, 29],
    binHexColors: ["#515866", "#0ed5a3", "#0099e4", "#8be1ff", "#ff45d0", "#d70066"],
    isDiff: false,
    step: 1,
    binLabels: null,
    binningType: "range",
    slug: "days_above_26c_wet-bulb",
    mapVersion: 3,
    isLatest: true,
    dataLabels: ["5th percentile", "average", "95th percentile"],
    methodUsedForMid: "mean",
    dataset: {
      id: 40301,
      name: "Number of days maximum wet-bulb temperature above 26°C",
      unit: "days",
      minValue: 0,
      maxValue: 365,
      dataVariables: null,
      pfDatasetUnitByUnit: {
        unitLong: "Number of days per year",
      },
      pfDatasetParentCategoryByParentCategory: {
        name: "heat",
        label: "Maps of temperature",
      },
      subCategory: "heat and humidity",
    },
  },
  {
    mapStyleId: "cle5pyznm003j01qmrmk88o4c",
    name: "Days above 26°C (78°F) wet-bulb",
    stops: [1, 4, 8, 15, 29],
    binHexColors: ["#515866", "#0ed5a3", "#0099e4", "#8be1ff", "#ff45d0", "#d70066"],
    isDiff: false,
    step: 1,
    binLabels: null,
    binningType: "range",
    slug: "days_above_26c_wet-bulb",
    mapVersion: 2,
    isLatest: false,
    dataLabels: ["5th percentile", "average", "95th percentile"],
    methodUsedForMid: "mean",
    dataset: {
      id: 40301,
      name: "Number of days maximum wet-bulb temperature above 26°C",
      unit: "days",
      minValue: 0,
      maxValue: 365,
      dataVariables: null,
      pfDatasetUnitByUnit: {
        unitLong: "Number of days per year",
      },
      pfDatasetParentCategoryByParentCategory: {
        name: "heat",
        label: "Maps of temperature",
      },
      subCategory: "heat and humidity",
    },
  },
  {
    mapStyleId: "cl5amu6qw000116nvng3b1p55",
    name: "Days above 26°C (78°F) wet-bulb",
    stops: [1, 4, 8, 15, 29],
    binHexColors: ["#515866", "#0ed5a3", "#0099e4", "#8be1ff", "#ff45d0", "#d70066"],
    isDiff: false,
    step: 1,
    binLabels: null,
    binningType: "range",
    slug: "days_above_26c_wet-bulb",
    mapVersion: 1,
    isLatest: false,
    dataLabels: ["5th percentile", "average", "95th percentile"],
    methodUsedForMid: "mean",
    dataset: {
      id: 40301,
      name: "Number of days maximum wet-bulb temperature above 26°C",
      unit: "days",
      minValue: 0,
      maxValue: 365,
      dataVariables: null,
      pfDatasetUnitByUnit: {
        unitLong: "Number of days per year",
      },
      pfDatasetParentCategoryByParentCategory: {
        name: "heat",
        label: "Maps of temperature",
      },
      subCategory: "heat and humidity",
    },
  },
  {
    mapStyleId: "cl5amzusp000614q9nl0de34p",
    name: "Days above 28°C (82°F) wet-bulb",
    stops: [1, 4, 8, 15, 29],
    binHexColors: ["#515866", "#0ed5a3", "#0099e4", "#8be1ff", "#ff45d0", "#d70066"],
    isDiff: false,
    step: 1,
    binLabels: null,
    binningType: "range",
    slug: "days_above_28c_wet-bulb",
    mapVersion: 1,
    isLatest: false,
    dataLabels: ["5th percentile", "average", "95th percentile"],
    methodUsedForMid: "mean",
    dataset: {
      id: 40302,
      name: "Number of days maximum wet-bulb temperature above 28°C",
      unit: "days",
      minValue: 0,
      maxValue: 365,
      dataVariables: null,
      pfDatasetUnitByUnit: {
        unitLong: "Number of days per year",
      },
      pfDatasetParentCategoryByParentCategory: {
        name: "heat",
        label: "Maps of temperature",
      },
      subCategory: "heat and humidity",
    },
  },
  {
    mapStyleId: "clhzzzbze000k01r1h9m1cql6",
    name: "Days above 28°C (82°F) wet-bulb",
    stops: [1, 4, 8, 15, 29],
    binHexColors: ["#515866", "#0ed5a3", "#0099e4", "#8be1ff", "#ff45d0", "#d70066"],
    isDiff: false,
    step: 1,
    binLabels: null,
    binningType: "range",
    slug: "days_above_28c_wet-bulb",
    mapVersion: 3,
    isLatest: true,
    dataLabels: ["5th percentile", "average", "95th percentile"],
    methodUsedForMid: "mean",
    dataset: {
      id: 40302,
      name: "Number of days maximum wet-bulb temperature above 28°C",
      unit: "days",
      minValue: 0,
      maxValue: 365,
      dataVariables: null,
      pfDatasetUnitByUnit: {
        unitLong: "Number of days per year",
      },
      pfDatasetParentCategoryByParentCategory: {
        name: "heat",
        label: "Maps of temperature",
      },
      subCategory: "heat and humidity",
    },
  },
  {
    mapStyleId: "cle8k0wbj003e01lnanhdzzv4",
    name: "Days above 28°C (82°F) wet-bulb",
    stops: [1, 4, 8, 15, 29],
    binHexColors: ["#515866", "#0ed5a3", "#0099e4", "#8be1ff", "#ff45d0", "#d70066"],
    isDiff: false,
    step: 1,
    binLabels: null,
    binningType: "range",
    slug: "days_above_28c_wet-bulb",
    mapVersion: 2,
    isLatest: false,
    dataLabels: ["5th percentile", "average", "95th percentile"],
    methodUsedForMid: "mean",
    dataset: {
      id: 40302,
      name: "Number of days maximum wet-bulb temperature above 28°C",
      unit: "days",
      minValue: 0,
      maxValue: 365,
      dataVariables: null,
      pfDatasetUnitByUnit: {
        unitLong: "Number of days per year",
      },
      pfDatasetParentCategoryByParentCategory: {
        name: "heat",
        label: "Maps of temperature",
      },
      subCategory: "heat and humidity",
    },
  },
  {
    mapStyleId: "cli004t7p02av01quclg5aoqe",
    name: "Days above 30°C (86°F) wet-bulb",
    stops: [1, 4, 8, 15, 29],
    binHexColors: ["#515866", "#0ed5a3", "#0099e4", "#8be1ff", "#ff45d0", "#d70066"],
    isDiff: false,
    step: 1,
    binLabels: null,
    binningType: "range",
    slug: "days_above_30c_wet-bulb",
    mapVersion: 3,
    isLatest: true,
    dataLabels: ["5th percentile", "average", "95th percentile"],
    methodUsedForMid: "mean",
    dataset: {
      id: 40303,
      name: "Number of days maximum wet-bulb temperature above 30°C",
      unit: "days",
      minValue: 0,
      maxValue: 365,
      dataVariables: null,
      pfDatasetUnitByUnit: {
        unitLong: "Number of days per year",
      },
      pfDatasetParentCategoryByParentCategory: {
        name: "heat",
        label: "Maps of temperature",
      },
      subCategory: "heat and humidity",
    },
  },
  {
    mapStyleId: "cle8k6mxy001401nzqjb2i47s",
    name: "Days above 30°C (86°F) wet-bulb",
    stops: [1, 4, 8, 15, 29],
    binHexColors: ["#515866", "#0ed5a3", "#0099e4", "#8be1ff", "#ff45d0", "#d70066"],
    isDiff: false,
    step: 1,
    binLabels: null,
    binningType: "range",
    slug: "days_above_30c_wet-bulb",
    mapVersion: 2,
    isLatest: false,
    dataLabels: ["5th percentile", "average", "95th percentile"],
    methodUsedForMid: "mean",
    dataset: {
      id: 40303,
      name: "Number of days maximum wet-bulb temperature above 30°C",
      unit: "days",
      minValue: 0,
      maxValue: 365,
      dataVariables: null,
      pfDatasetUnitByUnit: {
        unitLong: "Number of days per year",
      },
      pfDatasetParentCategoryByParentCategory: {
        name: "heat",
        label: "Maps of temperature",
      },
      subCategory: "heat and humidity",
    },
  },
  {
    mapStyleId: "cl5an7fl4000216nv693x0atj",
    name: "Days above 30°C (86°F) wet-bulb",
    stops: [1, 4, 8, 15, 29],
    binHexColors: ["#515866", "#0ed5a3", "#0099e4", "#8be1ff", "#ff45d0", "#d70066"],
    isDiff: false,
    step: 1,
    binLabels: null,
    binningType: "range",
    slug: "days_above_30c_wet-bulb",
    mapVersion: 1,
    isLatest: false,
    dataLabels: ["5th percentile", "average", "95th percentile"],
    methodUsedForMid: "mean",
    dataset: {
      id: 40303,
      name: "Number of days maximum wet-bulb temperature above 30°C",
      unit: "days",
      minValue: 0,
      maxValue: 365,
      dataVariables: null,
      pfDatasetUnitByUnit: {
        unitLong: "Number of days per year",
      },
      pfDatasetParentCategoryByParentCategory: {
        name: "heat",
        label: "Maps of temperature",
      },
      subCategory: "heat and humidity",
    },
  },
  {
    mapStyleId: "cle8kep4j007q01qm7mlwazow",
    name: "Days above 32°C (90°F) wet-bulb",
    stops: [1, 4, 8, 15, 29],
    binHexColors: ["#515866", "#0ed5a3", "#0099e4", "#8be1ff", "#ff45d0", "#d70066"],
    isDiff: false,
    step: 1,
    binLabels: null,
    binningType: "range",
    slug: "days_above_32c_wet-bulb",
    mapVersion: 2,
    isLatest: false,
    dataLabels: ["5th percentile", "average", "95th percentile"],
    methodUsedForMid: "mean",
    dataset: {
      id: 40304,
      name: "Number of days maximum wet-bulb temperature above 32°C",
      unit: "days",
      minValue: 0,
      maxValue: 365,
      dataVariables: null,
      pfDatasetUnitByUnit: {
        unitLong: "Number of days per year",
      },
      pfDatasetParentCategoryByParentCategory: {
        name: "heat",
        label: "Maps of temperature",
      },
      subCategory: "heat and humidity",
    },
  },
  {
    mapStyleId: "cl5anc752002m14ung7uqotzq",
    name: "Days above 32°C (90°F) wet-bulb",
    stops: [1, 4, 8, 15, 29],
    binHexColors: ["#515866", "#0ed5a3", "#0099e4", "#8be1ff", "#ff45d0", "#d70066"],
    isDiff: false,
    step: 1,
    binLabels: null,
    binningType: "range",
    slug: "days_above_32c_wet-bulb",
    mapVersion: 1,
    isLatest: false,
    dataLabels: ["5th percentile", "average", "95th percentile"],
    methodUsedForMid: "mean",
    dataset: {
      id: 40304,
      name: "Number of days maximum wet-bulb temperature above 32°C",
      unit: "days",
      minValue: 0,
      maxValue: 365,
      dataVariables: null,
      pfDatasetUnitByUnit: {
        unitLong: "Number of days per year",
      },
      pfDatasetParentCategoryByParentCategory: {
        name: "heat",
        label: "Maps of temperature",
      },
      subCategory: "heat and humidity",
    },
  },
  {
    mapStyleId: "cli00aegl02cf01pn98g44e3e",
    name: "Days above 32°C (90°F) wet-bulb",
    stops: [1, 4, 8, 15, 29],
    binHexColors: ["#515866", "#0ed5a3", "#0099e4", "#8be1ff", "#ff45d0", "#d70066"],
    isDiff: false,
    step: 1,
    binLabels: null,
    binningType: "range",
    slug: "days_above_32c_wet-bulb",
    mapVersion: 3,
    isLatest: true,
    dataLabels: ["5th percentile", "average", "95th percentile"],
    methodUsedForMid: "mean",
    dataset: {
      id: 40304,
      name: "Number of days maximum wet-bulb temperature above 32°C",
      unit: "days",
      minValue: 0,
      maxValue: 365,
      dataVariables: null,
      pfDatasetUnitByUnit: {
        unitLong: "Number of days per year",
      },
      pfDatasetParentCategoryByParentCategory: {
        name: "heat",
        label: "Maps of temperature",
      },
      subCategory: "heat and humidity",
    },
  },
  {
    mapStyleId: "cl5anh0ke003a14pm9u2g5rdq",
    name: "10 hottest wet-bulb days",
    stops: [1, 8, 15, 26, 32],
    binHexColors: ["#515866", "#0ed5a3", "#0099e4", "#8be1ff", "#ff45d0", "#d70066"],
    isDiff: false,
    step: 1,
    binLabels: null,
    binningType: "range",
    slug: "10_hottest_wet-bulb_days",
    mapVersion: 1,
    isLatest: false,
    dataLabels: ["5th percentile", "average", "95th percentile"],
    methodUsedForMid: "mean",
    dataset: {
      id: 40305,
      name: "Wet-bulb temperature of the 10 hottest days in any given year",
      unit: "°C",
      minValue: -30,
      maxValue: 60,
      dataVariables: null,
      pfDatasetUnitByUnit: {
        unitLong: "Temperature (°C)",
      },
      pfDatasetParentCategoryByParentCategory: {
        name: "heat",
        label: "Maps of temperature",
      },
      subCategory: "heat and humidity",
    },
  },
  {
    mapStyleId: "cli00g7pj00f501pn7ox1hz7b",
    name: "10 hottest wet-bulb days",
    stops: [1, 8, 15, 26, 32],
    binHexColors: ["#515866", "#0ed5a3", "#0099e4", "#8be1ff", "#ff45d0", "#d70066"],
    isDiff: false,
    step: 1,
    binLabels: null,
    binningType: "range",
    slug: "10_hottest_wet-bulb_days",
    mapVersion: 3,
    isLatest: true,
    dataLabels: ["5th percentile", "average", "95th percentile"],
    methodUsedForMid: "mean",
    dataset: {
      id: 40305,
      name: "Wet-bulb temperature of the 10 hottest days in any given year",
      unit: "°C",
      minValue: -30,
      maxValue: 60,
      dataVariables: null,
      pfDatasetUnitByUnit: {
        unitLong: "Temperature (°C)",
      },
      pfDatasetParentCategoryByParentCategory: {
        name: "heat",
        label: "Maps of temperature",
      },
      subCategory: "heat and humidity",
    },
  },
  {
    mapStyleId: "cle8kk10600cp01te0c5ggrkz",
    name: "10 hottest wet-bulb days",
    stops: [1, 8, 15, 26, 32],
    binHexColors: ["#515866", "#0ed5a3", "#0099e4", "#8be1ff", "#ff45d0", "#d70066"],
    isDiff: false,
    step: 1,
    binLabels: null,
    binningType: "range",
    slug: "10_hottest_wet-bulb_days",
    mapVersion: 2,
    isLatest: false,
    dataLabels: ["5th percentile", "average", "95th percentile"],
    methodUsedForMid: "mean",
    dataset: {
      id: 40305,
      name: "Wet-bulb temperature of the 10 hottest days in any given year",
      unit: "°C",
      minValue: -30,
      maxValue: 60,
      dataVariables: null,
      pfDatasetUnitByUnit: {
        unitLong: "Temperature (°C)",
      },
      pfDatasetParentCategoryByParentCategory: {
        name: "heat",
        label: "Maps of temperature",
      },
      subCategory: "heat and humidity",
    },
  },
  {
    mapStyleId: "clhufmhfn023y01pn976z0gws",
    name: "Change in total annual precipitation",
    stops: [-100, -50, -25, 25, 50, 101],
    binHexColors: ["#a36440", "#d98600", "#ffab24", "#515866", "#25a8b7", "#007ea7", "#003459"],
    isDiff: true,
    step: 1,
    binLabels: null,
    binningType: "range",
    slug: "change_in_total_annual_precipitation",
    mapVersion: 3,
    isLatest: true,
    dataLabels: ["5th percentile", "50th percentile", "95th percentile"],
    methodUsedForMid: "median",
    dataset: {
      id: 40601,
      name: "Annual sum precipitation; differences relative to 1971-2000",
      unit: "mm",
      minValue: -5943,
      maxValue: 15004,
      dataVariables: null,
      pfDatasetUnitByUnit: {
        unitLong: "Change in precipitation (mm)",
      },
      pfDatasetParentCategoryByParentCategory: {
        name: "water",
        label: "Maps of precipitation",
      },
      subCategory: null,
    },
  },
  {
    mapStyleId: "cl56ktc2f000l14l930kj9hcl",
    name: "Change in total annual precipitation",
    stops: [-100, -50, -25, 25, 50, 101],
    binHexColors: ["#a36440", "#d98600", "#ffab24", "#515866", "#25a8b7", "#007ea7", "#003459"],
    isDiff: true,
    step: 1,
    binLabels: null,
    binningType: "range",
    slug: "change_in_total_annual_precipitation",
    mapVersion: 1,
    isLatest: false,
    dataLabels: ["5th percentile", "50th percentile", "95th percentile"],
    methodUsedForMid: "median",
    dataset: {
      id: 40601,
      name: "Annual sum precipitation; differences relative to 1971-2000",
      unit: "mm",
      minValue: -5943,
      maxValue: 15004,
      dataVariables: null,
      pfDatasetUnitByUnit: {
        unitLong: "Change in precipitation (mm)",
      },
      pfDatasetParentCategoryByParentCategory: {
        name: "water",
        label: "Maps of precipitation",
      },
      subCategory: null,
    },
  },
  {
    mapStyleId: "cl5ao7geu001z14rzmwumk3do",
    name: "Change in snowy days",
    stops: [-22, -7, -2, 1],
    binHexColors: ["#a36440", "#d98600", "#ffab24", "#515866", "#25a8b7"],
    isDiff: true,
    step: 1,
    binLabels: null,
    binningType: "range",
    slug: "change_in_snowy_days",
    mapVersion: 1,
    isLatest: false,
    dataLabels: ["5th percentile", "50th percentile", "95th percentile"],
    methodUsedForMid: "median",
    dataset: {
      id: 40614,
      name: "Number of days with daily total precipitation greater than or equal to 1mm and average temperature less than 0°C; differences relative to 1971-2000",
      unit: "days",
      minValue: -230,
      maxValue: 294,
      dataVariables: null,
      pfDatasetUnitByUnit: {
        unitLong: "Number of days per year",
      },
      pfDatasetParentCategoryByParentCategory: {
        name: "water",
        label: "Maps of precipitation",
      },
      subCategory: null,
    },
  },
  {
    mapStyleId: "clhyixhg0028y01qu89iu8e2l",
    name: "Change in snowy days",
    stops: [-22, -7, -2, 1],
    binHexColors: ["#a36440", "#d98600", "#ffab24", "#515866", "#25a8b7"],
    isDiff: true,
    step: 1,
    binLabels: null,
    binningType: "range",
    slug: "change_in_snowy_days",
    mapVersion: 3,
    isLatest: true,
    dataLabels: ["5th percentile", "50th percentile", "95th percentile"],
    methodUsedForMid: "median",
    dataset: {
      id: 40614,
      name: "Number of days with daily total precipitation greater than or equal to 1mm and average temperature less than 0°C; differences relative to 1971-2000",
      unit: "days",
      minValue: -230,
      maxValue: 294,
      dataVariables: null,
      pfDatasetUnitByUnit: {
        unitLong: "Number of days per year",
      },
      pfDatasetParentCategoryByParentCategory: {
        name: "water",
        label: "Maps of precipitation",
      },
      subCategory: null,
    },
  },
  {
    mapStyleId: "clhuooyb7004n01qu85kh24fo",
    name: "Change in dry hot days",
    stops: [0, 8, 31, 61],
    binHexColors: ["#25a8b7", "#515866", "#ffab24", "#d98600", "#a36440"],
    isDiff: true,
    step: 1,
    binLabels: null,
    binningType: "range",
    slug: "change_in_dry_hot_days",
    mapVersion: 3,
    isLatest: true,
    dataLabels: ["5th percentile", "50th percentile", "95th percentile"],
    methodUsedForMid: "median",
    dataset: {
      id: 40607,
      name: "Number of days with total precipitation less than historic 10th percentile and maximum temperature greater than or equal to the historic 90th percentile; differences relative to 1971-2000",
      unit: "days",
      minValue: -8,
      maxValue: 251,
      dataVariables: null,
      pfDatasetUnitByUnit: {
        unitLong: "Number of days per year",
      },
      pfDatasetParentCategoryByParentCategory: {
        name: "water",
        label: "Maps of precipitation",
      },
      subCategory: null,
    },
  },
  {
    mapStyleId: "cl589lwuf000814unun1bxaxj",
    name: "Change in dry hot days",
    stops: [0, 8, 31, 61],
    binHexColors: ["#25a8b7", "#515866", "#ffab24", "#d98600", "#a36440"],
    isDiff: true,
    step: 1,
    binLabels: null,
    binningType: "range",
    slug: "change_in_dry_hot_days",
    mapVersion: 1,
    isLatest: false,
    dataLabels: ["5th percentile", "50th percentile", "95th percentile"],
    methodUsedForMid: "median",
    dataset: {
      id: 40607,
      name: "Number of days with total precipitation less than historic 10th percentile and maximum temperature greater than or equal to the historic 90th percentile; differences relative to 1971-2000",
      unit: "days",
      minValue: -8,
      maxValue: 251,
      dataVariables: null,
      pfDatasetUnitByUnit: {
        unitLong: "Number of days per year",
      },
      pfDatasetParentCategoryByParentCategory: {
        name: "water",
        label: "Maps of precipitation",
      },
      subCategory: null,
    },
  },
  {
    mapStyleId: "cl5aodqou005m14l92iv0mjnc",
    name: "Change in wettest 90 days",
    stops: [-50, -25, -12, 12, 25, 51],
    binHexColors: ["#a36440", "#d98600", "#ffab24", "#515866", "#25a8b7", "#007ea7", "#003459"],
    isDiff: true,
    step: 1,
    binLabels: null,
    binningType: "range",
    slug: "change_in_wettest_90_days",
    mapVersion: 1,
    isLatest: false,
    dataLabels: ["5th percentile", "50th percentile", "95th percentile"],
    methodUsedForMid: "median",
    dataset: {
      id: 40616,
      name: "Annual maximum 90-day precipitation sum; differences relative to 1971-2000",
      unit: "mm",
      minValue: -2718,
      maxValue: 5629,
      dataVariables: null,
      pfDatasetUnitByUnit: {
        unitLong: "Change in precipitation (mm)",
      },
      pfDatasetParentCategoryByParentCategory: {
        name: "water",
        label: "Maps of precipitation",
      },
      subCategory: null,
    },
  },
  {
    mapStyleId: "clhyj2rj6027601pr6h68953d",
    name: "Change in wettest 90 days",
    stops: [-50, -25, -12, 12, 25, 51],
    binHexColors: ["#a36440", "#d98600", "#ffab24", "#515866", "#25a8b7", "#007ea7", "#003459"],
    isDiff: true,
    step: 1,
    binLabels: null,
    binningType: "range",
    slug: "change_in_wettest_90_days",
    mapVersion: 3,
    isLatest: true,
    dataLabels: ["5th percentile", "50th percentile", "95th percentile"],
    methodUsedForMid: "median",
    dataset: {
      id: 40616,
      name: "Annual maximum 90-day precipitation sum; differences relative to 1971-2000",
      unit: "mm",
      minValue: -2718,
      maxValue: 5629,
      dataVariables: null,
      pfDatasetUnitByUnit: {
        unitLong: "Change in precipitation (mm)",
      },
      pfDatasetParentCategoryByParentCategory: {
        name: "water",
        label: "Maps of precipitation",
      },
      subCategory: null,
    },
  },
  {
    mapStyleId: "cl5anvbn3000416nvlqq0q625",
    name: "Change in frequency of “1-in-100-year” storm",
    stops: [1, 2, 3, 5],
    binHexColors: ["#ffab24", "#515866", "#25a8b7", "#007ea7", "#003459"],
    isDiff: true,
    step: 1,
    binLabels: null,
    binningType: "range",
    slug: "change_in_frequency_of_1-in-100_year_storm",
    mapVersion: 1,
    isLatest: false,
    dataLabels: ["5th percentile", "50th percentile", "95th percentile"],
    methodUsedForMid: "median",
    dataset: {
      id: 40612,
      name: "Future return period of the historic 1-100 year 1-day precipitation event",
      unit: "x as frequent",
      minValue: 0,
      maxValue: 45,
      dataVariables: null,
      pfDatasetUnitByUnit: {
        unitLong: "Times more/less frequent",
      },
      pfDatasetParentCategoryByParentCategory: {
        name: "water",
        label: "Maps of precipitation",
      },
      subCategory: null,
    },
  },
  {
    mapStyleId: "clhuot8cr024o01pnfdqf710x",
    name: "Change in frequency of “1-in-100-year” storm",
    stops: [1, 2, 3, 5],
    binHexColors: ["#ffab24", "#515866", "#25a8b7", "#007ea7", "#003459"],
    isDiff: true,
    step: 1,
    binLabels: null,
    binningType: "range",
    slug: "change_in_frequency_of_1-in-100_year_storm",
    mapVersion: 3,
    isLatest: true,
    dataLabels: ["5th percentile", "50th percentile", "95th percentile"],
    methodUsedForMid: "median",
    dataset: {
      id: 40612,
      name: "Future return period of the historic 1-100 year 1-day precipitation event",
      unit: "x as frequent",
      minValue: 0,
      maxValue: 45,
      dataVariables: null,
      pfDatasetUnitByUnit: {
        unitLong: "Times more/less frequent",
      },
      pfDatasetParentCategoryByParentCategory: {
        name: "water",
        label: "Maps of precipitation",
      },
      subCategory: null,
    },
  },
  {
    mapStyleId: "cl6kf3j3m003r15lji9052p2h",
    name: "Change in precipitation “1-in-100-year” storm",
    stops: [-1, 12, 25, 51],
    binHexColors: ["#ffab24", "#515866", "#25a8b7", "#007ea7", "#003459"],
    isDiff: true,
    step: 1,
    binLabels: null,
    binningType: "range",
    slug: "change_in_precipitation_1-in-100_year_storm",
    mapVersion: 1,
    isLatest: false,
    dataLabels: ["5th percentile", "50th percentile", "95th percentile"],
    methodUsedForMid: "median",
    dataset: {
      id: 40613,
      name: "Precipitation from the 1-100 year 1-day precipitation event ; differences relative to 1971-2000",
      unit: "mm",
      minValue: -217,
      maxValue: 1190,
      dataVariables: null,
      pfDatasetUnitByUnit: {
        unitLong: "Change in precipitation (mm)",
      },
      pfDatasetParentCategoryByParentCategory: {
        name: "water",
        label: "Maps of precipitation",
      },
      subCategory: null,
    },
  },
  {
    mapStyleId: "clhyiscjx028w01qu7vim9cml",
    name: "Change in precipitation “1-in-100-year” storm",
    stops: [-1, 12, 25, 51],
    binHexColors: ["#ffab24", "#515866", "#25a8b7", "#007ea7", "#003459"],
    isDiff: true,
    step: 1,
    binLabels: null,
    binningType: "range",
    slug: "change_in_precipitation_1-in-100_year_storm",
    mapVersion: 3,
    isLatest: true,
    dataLabels: ["5th percentile", "50th percentile", "95th percentile"],
    methodUsedForMid: "median",
    dataset: {
      id: 40613,
      name: "Precipitation from the 1-100 year 1-day precipitation event ; differences relative to 1971-2000",
      unit: "mm",
      minValue: -217,
      maxValue: 1190,
      dataVariables: null,
      pfDatasetUnitByUnit: {
        unitLong: "Change in precipitation (mm)",
      },
      pfDatasetParentCategoryByParentCategory: {
        name: "water",
        label: "Maps of precipitation",
      },
      subCategory: null,
    },
  },
  {
    mapStyleId: "cl82t4a5z002s14ochl1tq75f",
    name: "Change in water balance",
    stops: [-1, -0.5, 0.6, 1.1],
    binHexColors: ["#ec8a00", "#ffcd29", "#515866", "#baaf51", "#66a853"],
    isDiff: false,
    step: 0.1,
    binLabels: ["much drier", "drier", "unchanged", "wetter", "much wetter"],
    binningType: "range",
    slug: "change_in_water_balance",
    mapVersion: 1,
    isLatest: false,
    dataLabels: ["Dry year", "Average year", "Wet year"],
    methodUsedForMid: "median",
    dataset: {
      id: 40703,
      name: "Mean SPEI-12",
      unit: "z-score",
      minValue: -3.3,
      maxValue: 2.3,
      dataVariables: null,
      pfDatasetUnitByUnit: {
        unitLong: "Change in water balance",
      },
      pfDatasetParentCategoryByParentCategory: {
        name: "land",
        label: "Maps of dryness",
      },
      subCategory: null,
    },
  },
  {
    mapStyleId: "cli07tazp02d901pn5jjj5tvn",
    name: "Change in water balance",
    stops: [-1, -0.5, 0.6, 1.1],
    binHexColors: ["#ec8a00", "#ffcd29", "#515866", "#baaf51", "#66a853"],
    isDiff: false,
    step: 0.1,
    binLabels: ["much drier", "drier", "unchanged", "wetter", "much wetter"],
    binningType: "range",
    slug: "change_in_water_balance",
    mapVersion: 3,
    isLatest: true,
    dataLabels: ["Dry year", "Average year", "Wet year"],
    methodUsedForMid: "median",
    dataset: {
      id: 40703,
      name: "Mean SPEI-12",
      unit: "z-score",
      minValue: -3.3,
      maxValue: 2.3,
      dataVariables: null,
      pfDatasetUnitByUnit: {
        unitLong: "Change in water balance",
      },
      pfDatasetParentCategoryByParentCategory: {
        name: "land",
        label: "Maps of dryness",
      },
      subCategory: null,
    },
  },
  {
    mapStyleId: "cl8a0lz5c000m14l7ym1k9el4",
    name: "Likelihood of year-plus drought",
    stops: [11, 34, 51, 68, 91],
    binHexColors: ["#515866", "#baaf51", "#ffcd29", "#ec8a00", "#f24822", "#922912"],
    isDiff: false,
    step: 1,
    binLabels: null,
    binningType: "range",
    slug: "likelihood_of_year-plus_drought",
    mapVersion: 1,
    isLatest: false,
    dataLabels: ["5th percentile", "Average year", "95th percentile"],
    methodUsedForMid: "mean",
    dataset: {
      id: 40702,
      name: "Percent of total months with SPEI-12 less than or equal to -0.5",
      unit: "%",
      minValue: 0,
      maxValue: 100,
      dataVariables: null,
      pfDatasetUnitByUnit: {
        unitLong: "Annual likelihood (%)",
      },
      pfDatasetParentCategoryByParentCategory: {
        name: "land",
        label: "Maps of dryness",
      },
      subCategory: null,
    },
  },
  {
    mapStyleId: "cli4cyaez00oo01pn4twydvb7",
    name: "Likelihood of year-plus drought",
    stops: [11, 34, 51, 68, 91],
    binHexColors: ["#515866", "#baaf51", "#ffcd29", "#ec8a00", "#f24822", "#922912"],
    isDiff: false,
    step: 1,
    binLabels: null,
    binningType: "range",
    slug: "likelihood_of_year-plus_drought",
    mapVersion: 3,
    isLatest: true,
    dataLabels: ["5th percentile", "Average year", "95th percentile"],
    methodUsedForMid: "mean",
    dataset: {
      id: 40702,
      name: "Percent of total months with SPEI-12 less than or equal to -0.5",
      unit: "%",
      minValue: 0,
      maxValue: 100,
      dataVariables: null,
      pfDatasetUnitByUnit: {
        unitLong: "Annual likelihood (%)",
      },
      pfDatasetParentCategoryByParentCategory: {
        name: "land",
        label: "Maps of dryness",
      },
      subCategory: null,
    },
  },
  {
    mapStyleId: "cli4ctifx02hx01r03gfygf6f",
    name: "Likelihood of year-plus extreme drought",
    stops: [11, 21, 34, 51, 67],
    binHexColors: ["#515866", "#baaf51", "#ffcd29", "#ec8a00", "#f24822", "#922912"],
    isDiff: false,
    step: 1,
    binLabels: null,
    binningType: "range",
    slug: "likelihood_of_year-plus_extreme_drought",
    mapVersion: 3,
    isLatest: true,
    dataLabels: ["5th percentile", "Average year", "95th percentile"],
    methodUsedForMid: "mean",
    dataset: {
      id: 40701,
      name: "Percent of total months with SPEI-12 less than or equal to -1.6",
      unit: "%",
      minValue: 0,
      maxValue: 100,
      dataVariables: null,
      pfDatasetUnitByUnit: {
        unitLong: "Annual likelihood (%)",
      },
      pfDatasetParentCategoryByParentCategory: {
        name: "land",
        label: "Maps of dryness",
      },
      subCategory: null,
    },
  },
  {
    mapStyleId: "cl8a0g5rj005p14p5qywg0ugr",
    name: "Likelihood of year-plus extreme drought",
    stops: [11, 21, 34, 51, 67],
    binHexColors: ["#515866", "#baaf51", "#ffcd29", "#ec8a00", "#f24822", "#922912"],
    isDiff: false,
    step: 1,
    binLabels: null,
    binningType: "range",
    slug: "likelihood_of_year-plus_extreme_drought",
    mapVersion: 1,
    isLatest: false,
    dataLabels: ["5th percentile", "Average year", "95th percentile"],
    methodUsedForMid: "mean",
    dataset: {
      id: 40701,
      name: "Percent of total months with SPEI-12 less than or equal to -1.6",
      unit: "%",
      minValue: 0,
      maxValue: 100,
      dataVariables: null,
      pfDatasetUnitByUnit: {
        unitLong: "Annual likelihood (%)",
      },
      pfDatasetParentCategoryByParentCategory: {
        name: "land",
        label: "Maps of dryness",
      },
      subCategory: null,
    },
  },
  {
    mapStyleId: "cli07z7bu02fm01pg4vqk9ukg",
    name: "Change in wildfire danger days",
    stops: [-6, 7, 14, 30, 60],
    binHexColors: ["#baaf51", "#515866", "#ffcd29", "#ec8a00", "#f24822", "#922912"],
    isDiff: false,
    step: 1,
    binLabels: null,
    binningType: "range",
    slug: "change_in_wildfire_danger_days",
    mapVersion: 3,
    isLatest: true,
    dataLabels: ["5th percentile", "Average year", "95th percentile"],
    methodUsedForMid: "median",
    dataset: {
      id: 40704,
      name: "Number of days per year with FWI exceeding the 1972-2000 95th percentile; differences relative to 1972-2000",
      unit: "days",
      minValue: -18,
      maxValue: 155,
      dataVariables: null,
      pfDatasetUnitByUnit: {
        unitLong: "Number of days per year",
      },
      pfDatasetParentCategoryByParentCategory: {
        name: "land",
        label: "Maps of dryness",
      },
      subCategory: null,
    },
  },
  {
    mapStyleId: "cl8a0rh65006414qloma90grc",
    name: "Change in wildfire danger days",
    stops: [-6, 7, 14, 30, 60],
    binHexColors: ["#baaf51", "#515866", "#ffcd29", "#ec8a00", "#f24822", "#922912"],
    isDiff: false,
    step: 1,
    binLabels: null,
    binningType: "range",
    slug: "change_in_wildfire_danger_days",
    mapVersion: 1,
    isLatest: false,
    dataLabels: ["5th percentile", "Average year", "95th percentile"],
    methodUsedForMid: "median",
    dataset: {
      id: 40704,
      name: "Number of days per year with FWI exceeding the 1972-2000 95th percentile; differences relative to 1972-2000",
      unit: "days",
      minValue: -18,
      maxValue: 155,
      dataVariables: null,
      pfDatasetUnitByUnit: {
        unitLong: "Number of days per year",
      },
      pfDatasetParentCategoryByParentCategory: {
        name: "land",
        label: "Maps of dryness",
      },
      subCategory: null,
    },
  },
  {
    mapStyleId: "clnbsx0xv03lv01pb5b2kbjau",
    name: "Climate zones",
    stops: [
      2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26,
      27,
    ],
    binHexColors: [
      "#1e8245",
      "#58b368",
      "#81d895",
      "#422d1e",
      "#7f5539",
      "#947b73",
      "#c9a47e",
      "#86b9fe",
      "#007dff",
      "#0009ff",
      "#00009f",
      "#1b4965",
      "#0081a7",
      "#27adbf",
      "#74d2df",
      "#a7fafa",
      "#ffc500",
      "#ff9200",
      "#e85d04",
      "#ff0a0a",
      "#f559ba",
      "#9b2226",
      "#d14553",
      "#f67f6f",
      "#ffc7c2",
      "#5e548e",
      "#be95c4",
    ],
    isDiff: false,
    step: 1,
    binLabels: [
      "Tropical rainforest",
      "Tropical monsoon",
      "Tropical savanna",
      "Dry semi-arid (or steppe) hot",
      "Dry semi-arid (or steppe) cold",
      "Dry arid desert hot",
      "Dry arid desert cold",
      "Temperate no dry season hot summer",
      "Temperate no dry season warm summer",
      "Temperate no dry season cold summer",
      "Temperate dry summer hot summer",
      "Temperate dry summer warm summer",
      "Temperate dry summer cold summer",
      "Temperate dry winter hot summer",
      "Temperate dry winter warm summer",
      "Temperate dry winter cold summer",
      "Continental no dry season hot summer",
      "Continental no dry season warm summer",
      "Continental no dry season cold summer",
      "Continental dry summer hot summer",
      "Continental dry summer warm summer",
      "Continental dry summer cold summer",
      "Contintental dry winter hot summer",
      "Continental dry winter warm summer",
      "Continental dry winter cold summer",
      "Polar tundra",
      "Polar ice cap",
    ],
    binningType: "number",
    slug: "climate_zones",
    mapVersion: 3,
    isLatest: true,
    dataLabels: ["5th percentile", "50th percentile", "95th percentile"],
    methodUsedForMid: "mean",
    dataset: {
      id: 40901,
      name: "Climate zones",
      unit: "class",
      minValue: 1,
      maxValue: 52,
      dataVariables: null,
      pfDatasetUnitByUnit: {
        unitLong: "Climate zone",
      },
      pfDatasetParentCategoryByParentCategory: {
        name: "other",
        label: "Other maps",
      },
      subCategory: null,
    },
  },
  {
    mapStyleId: "clr4pe2k400ds01p56a6jc3en",
    name: "Days above 45°C (113°F)",
    stops: [1, 8, 31, 91, 181],
    binHexColors: ["#515866", "#0ed5a3", "#0099e4", "#8be1ff", "#ff45d0", "#d70066"],
    isDiff: false,
    step: 1,
    binLabels: null,
    binningType: "range",
    slug: "days_above_45c",
    mapVersion: 3,
    isLatest: true,
    dataLabels: ["Cooler year", "Average year", "Warmer year"],
    methodUsedForMid: "mean",
    dataset: {
      id: 40107,
      name: "Days above 45°C (113°F)",
      unit: "days",
      minValue: 0,
      maxValue: 365,
      dataVariables: ["5th percentile", "average", "95th percentile"],
      pfDatasetUnitByUnit: {
        unitLong: "Number of days per year",
      },
      pfDatasetParentCategoryByParentCategory: {
        name: "heat",
        label: "Maps of temperature",
      },
      subCategory: "increasing heat",
    },
  },
  {
    mapStyleId: "clrjfvn2900oi01pe6lzhesid",
    name: "10 hottest nights",
    stops: [1, 8, 15, 26, 32],
    binHexColors: ["#515866", "#0ed5a3", "#0099e4", "#8be1ff", "#ff45d0", "#d70066"],
    isDiff: false,
    step: 1,
    binLabels: null,
    binningType: "range",
    slug: "10_hottest_nights",
    mapVersion: 3,
    isLatest: true,
    dataLabels: ["5th percentile", "50th percentile", "95th percentile"],
    methodUsedForMid: "mean",
    dataset: {
      id: 40206,
      name: "Mean minimum temperature of the 10 hottest days in any given year",
      unit: "°C",
      minValue: -10,
      maxValue: 40,
      dataVariables: ["5th percentile", "50th percentile", "95th percentile"],
      pfDatasetUnitByUnit: {
        unitLong: "Temperature (°C)",
      },
      pfDatasetParentCategoryByParentCategory: {
        name: "heat",
        label: "Maps of temperature",
      },
      subCategory: "decreasing cold",
    },
  },
  {
    mapStyleId: "cltx1yoqo00eb01qwcebs7d3z",
    name: "Average winter temperature",
    stops: [-20, -8, 0, 5, 20],
    binHexColors: ["#515866", "#0ed5a3", "#0099e4", "#8be1ff", "#ff45d0", "#d70066"],
    isDiff: false,
    step: 1,
    binLabels: null,
    binningType: "range",
    slug: "average_winter_temperature",
    mapVersion: 3,
    isLatest: true,
    dataLabels: ["5th percentile", "50th percentile", "95th percentile"],
    methodUsedForMid: "mean",
    dataset: {
      id: 40207,
      name: "Mean average winter temperature",
      unit: "°C",
      minValue: -46,
      maxValue: 37,
      dataVariables: ["5th percentile", "50th percentile", "95th percentile"],
      pfDatasetUnitByUnit: {
        unitLong: "Temperature (°C)",
      },
      pfDatasetParentCategoryByParentCategory: {
        name: "heat",
        label: "Maps of temperature",
      },
      subCategory: "decreasing cold",
    },
  },
];
