export const colors = {
  cream: "#f0f1e8",
  lightCream: "#f8f9f3",
  darkPurple: "#2a172d",
  white: "#fdfdfd",
  secondaryWhite: "#fefffc",
  purple: "#851fff",
  primaryGray: "#a2a2a2",
  secondaryGray: "#adadad",
  grey: "#b6b4b7",
  lightGrey: "#cccccc",
  whiteSmoke: "#f5f5f5",
  blue: "#006CC9",
  silver: "#c0c0c0",
  textBlack: "#1b1a1c",
  red: "#da1720",
  black: "#000000",
  whiteOriginal: "#ffffff",
  dimBlack: "#1c101e",
  lightGrey2: "#787279",
};

export const size = {
  mobile: "375px",
  mobileMax: "767px",
  tablet: "768px",
  tabletMax: "1199px",
  laptop: "1200px",
  desktop: "1440px",
};
