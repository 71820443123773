import React, { useCallback, useState } from "react";
import styled from "styled-components";
import { types } from "@probable-futures/lib";
import { components, styles } from "@probable-futures/components-lib";

import ChatbotIcon from "../assets/icons/chatbot.svg";
import CancelIcon from "../assets/icons/cancel-icon.svg";
import { sendDataToChatbot } from "../utils/chatbot";
import { useTranslation } from "../contexts/TranslationContext";

type IFrameProps = { selectedData?: types.Map; degrees?: number };

type StyledIframeContainerProps = { isChatOpen: boolean };

const StyledButton = styled.button`
  border: none;
  background-color: transparent;
  border-radius: 50%;
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 25px;
`;

const StyledChatButton = styled.button`
  border: none;
  background-color: transparent;
  border-radius: 50%;
  cursor: pointer;
`;

const StyledIframeContainer = styled.div`
  transition: opacity 0.5s;
  opacity: ${({ isChatOpen }: StyledIframeContainerProps) => (isChatOpen ? 1 : 0)};
  position: relative;
  top: 12px;
`;

const StyledIframe = styled.iframe`
  width: 480px;
  height: 570px;
  border: none;
`;

const ChatIcon = styled.img`
  background-color: #fdfdfd;
  border-radius: 6px;
  box-shadow: 0 3px 5px 0 rgb(56 22 63 / 50%);
  outline: 0;
  padding: 3px;
`;

const CancelButton = styled.img`
  position: absolute;
  right: 3px;
  top: 4px;
  width: 20px;
  height: 20px;
`;

const chatbotUrl = process.env.REACT_APP_CHATBOT_URL;

const ChatbotIframe = ({ selectedData, degrees }: IFrameProps) => {
  const { translate } = useTranslation();

  const [isChatOpen, setIsChatOpen] = useState(false);
  const [showChatbotTooltip, setShowChatbotTooltip] = useState(false);

  const chatBotTitle = translate("mapControl.chatBotTitle");

  const toggleChat = () => {
    setIsChatOpen(!isChatOpen);
  };

  const onIframeLoad = useCallback(() => {
    setTimeout(() => {
      sendDataToChatbot({ dataset: selectedData, warmingScenario: degrees, action: "fetchData" });
    }, 3000);
  }, [degrees, selectedData]);

  return (
    <div>
      <StyledChatButton>
        <components.ControlsTooltip
          tooltipContent={chatBotTitle}
          show={showChatbotTooltip}
          onClickOutside={() => setShowChatbotTooltip(false)}
        >
          <styles.ControlButton
            disabled={!!!selectedData}
            title={chatBotTitle}
            onClick={toggleChat}
            onMouseEnter={() => setShowChatbotTooltip(true)}
            onMouseLeave={() => setShowChatbotTooltip(false)}
          >
            {!isChatOpen && <ChatIcon src={ChatbotIcon} alt="Chatbot icon" />}
          </styles.ControlButton>
        </components.ControlsTooltip>
      </StyledChatButton>

      <StyledIframeContainer isChatOpen={isChatOpen}>
        {isChatOpen && (
          <>
            <StyledButton onClick={toggleChat}>
              <CancelButton src={CancelIcon} alt="cancel icon" />
            </StyledButton>
            <StyledIframe id="chatbot-id" src={chatbotUrl} title="Chatbot" onLoad={onIframeLoad} />
          </>
        )}
      </StyledIframeContainer>
    </div>
  );
};

export default ChatbotIframe;
